import React, { useState } from 'react';
import BlockAlgorithm from "./BlockAlgorithm";

import {BlockOutlined, TeamOutlined} from '@ant-design/icons';
import { Layout, Menu, theme } from 'antd';
import HashAlgorithm from "./HashAlgorithm";
import MacAlgorithm from "./MacAlgorithm";
import DrbgAlgorithm from "./DrbgAlgorithm";
import BatchAlgorithm from "./BatchAlgorithm";
import AdminAPI from "./AdminAPI";
import AdminAPI2 from "./AdminAPI2";

const { Header, Content, Footer, Sider } = Layout;
const WizCryptoLibDemo = () => {
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();


    const [selectedComponent, setSelectedComponent] = useState<string>('BLOCK');

    const renderComponent = () => {
        console.log(selectedComponent);
        switch (selectedComponent) {
            case 'BLOCK':
                return <BlockAlgorithm />;
            case 'HASH':
                return <HashAlgorithm />;
            case 'MAC':
                return <MacAlgorithm />;
            case 'DRBG':
                return <DrbgAlgorithm />;
            case 'ADMIN_API':
                return <AdminAPI />;
            case 'ADMIN_API2':
                return <AdminAPI2 />;
            default:
                return <BatchAlgorithm />;
        }
    };

    const handleClick = (e: any) => {
        console.log('click ', e);
        setSelectedComponent(e.key);
    };

    const items = [
        {
            key: `BLOCK`,
            icon: <BlockOutlined />,
            label: `블록암호알고리즘`,
        },
        {
            key: `HASH`,
            icon: <BlockOutlined />,
            label: `해시알고리즘`,
        },
        {
            key: `MAC`,
            icon: <BlockOutlined />,
            label: `MAC 알고리즘`,
        },
        {
            key: `DRBG`,
            icon: <BlockOutlined />,
            label: `DRBG 알고리즘`,
        },
        {
            key: `BATCH`,
            icon: <BlockOutlined />,
            label: `배치작업`,
        },
        {
            key: 'sub1',
            label: '운영자API',
            icon: <TeamOutlined />,
            children: [
                {
                    key: `ADMIN_API`,
                    icon: <BlockOutlined />,
                    label: `자가시험`,
                },
                {
                    key: `ADMIN_API2`,
                    icon: <BlockOutlined />,
                    label: `무결성시험`,
                },
            ],
        },

    ];

    return (
        <Layout>
            <Sider
                breakpoint="lg"
                collapsedWidth="0"
                onBreakpoint={(broken) => {
                    console.log(broken);
                }}
                onCollapse={(collapsed, type) => {
                    console.log(collapsed, type);
                }}
            >
                <div className="demo-logo-vertical" />
                <Menu theme="dark" mode="inline" onClick={handleClick} defaultSelectedKeys={['BLOCK']} items={items} />
            </Sider>
            <Layout>
                <Content style={{ margin: '24px 16px 0' }}>
                    <div
                        style={{
                            padding: 24,
                            minHeight: 360,
                            background: colorBgContainer,
                            borderRadius: borderRadiusLG,
                        }}
                    >
                        {renderComponent()}
                    </div>
                </Content>
                <Footer style={{ textAlign: 'center' }}>
                    Appdign ©{2024} WizCryptoLib Demo
                </Footer>
            </Layout>
        </Layout>
    );

};

export default WizCryptoLibDemo;
