import React, {ChangeEvent, useCallback, useState} from 'react';
import axios from 'axios';
import {Button, message, Radio, Spin, Upload, UploadProps} from 'antd';
import {LoadingOutlined, UploadOutlined} from "@ant-design/icons";
const AdminAPI = () => {

    const [isUpload, setIsUpload] = useState<boolean>(false);
    const [result, setResult] = useState<string>('');

    const [fileList, setFileList] = useState<any>([]);
    const props: UploadProps = {
        name: 'file',
        action: `${window.location.protocol}//${window.location.host}/api/admin/self`,
        fileList: fileList,
        headers: {
        },
        async onChange(info) {



            const {status, response, name} = info.file;

            setFileList(info.fileList);
            // 업로드 중
            if (status === 'uploading') {
                console.log(`업로드 중: ${info.file.name}`);
                setIsUpload(true);
            }

            // 업로드 성공
            if (status === 'done') {
                const byteCharacters = atob(response);
                const byteNumbers = new Array(byteCharacters.length).fill(0).map((_, i) =>
                    byteCharacters.charCodeAt(i)
                );
                const byteArray = new Uint8Array(byteNumbers);

                // Blob 생성
                const blob = new Blob([byteArray], {type: "application/txt"});

                const text = await blob.text(); // Blob을 텍스트로 변환
                setResult(text); // 텍스트 상태 업데이트
                setIsUpload(false);
                setFileList([]);
            }


            // 업로드 실패
            if (status === 'error') {
                message.error(`${info.file.name} 업로드 중 문제가 발생했습니다.`);
                setIsUpload(false);
            }
        },
    };


    // 텍스트에서 ':' 뒤를 스타일링 (줄바꿈을 기준으로 처리)
    // '=' 뒤 첫 번째 ':'을 찾고 그 뒤를 스타일링
    const formatText = (inputText: string) => {
        const lines = inputText.split('\n'); // 줄바꿈을 기준으로 나누기

        return lines.map((line, lineIndex) => {
            // '=' 이후의 텍스트만 찾기 위해 '='을 기준으로 분리
            const firstEqualIndex = line.indexOf('=');
            if (firstEqualIndex === -1) {
                return <div key={lineIndex}>{line}</div>; // '='가 없다면 그냥 출력
            }

            const beforeEqual = line.substring(0, firstEqualIndex + 1); // '=' 전 텍스트
            const afterEqual = line.substring(firstEqualIndex + 1); // '=' 이후 텍스트

            // 첫 번째 ':'을 찾아 그 뒤를 스타일링
            const firstColonIndex = afterEqual.indexOf(':');
            if (firstColonIndex === -1) {
                return <div key={lineIndex}>{line}</div>; // ':'가 없다면 그냥 출력
            }

            const beforeColon = afterEqual.substring(0, firstColonIndex + 1); // ':' 전 텍스트
            const afterColon = afterEqual.substring(firstColonIndex + 1); // ':' 이후 텍스트

            return (
                <div key={lineIndex}>
                    {beforeEqual}
                    {beforeColon}
                    <span style={{ fontWeight: 'bold', color:  afterColon === '성공' ? 'blue' : 'red' }}>
                        {afterColon}
                      </span>
                </div>
            );
        });
    };


    return (

        <div>
            {isUpload &&
                <div className="container">
                    <Spin indicator={<LoadingOutlined style={{ fontSize: 96 }} spin />} />
                </div>
            }

            <div style={{marginTop: "30px"}}>
                <Upload {...props}>
                    <Button icon={<UploadOutlined/>}>암호모듈 파일업로드</Button>
                </Upload>
            </div>
            {result.length > 0 &&
                <div style={{marginTop: "20px",
                    whiteSpace: 'pre-wrap'}}>
                    {formatText(result)}
                </div>
            }

        </div>
    );
};

export default AdminAPI;
