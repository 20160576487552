import React, {ChangeEvent, useCallback, useState} from 'react';
import axios from 'axios';
import {Button, message, Spin, Upload, UploadProps} from 'antd';
import {LoadingOutlined, UploadOutlined} from "@ant-design/icons";
const BatchAlgorithm = () => {

    const [isUpload, setIsUpload] = useState<boolean>(false);

    const props: UploadProps = {
        name: 'file',
        action: `${window.location.protocol}//${window.location.host}/api/batch/proc`,
        headers: {
        },
        onChange(info) {

            const { status, response,  name} = info.file;

            // 업로드 중
            if (status === 'uploading') {
                console.log(`업로드 중: ${info.file.name}`);
                setIsUpload(true);
            }

            // 업로드 성공
            if (status === 'done') {
                const byteCharacters = atob(response);
                const byteNumbers = new Array(byteCharacters.length).fill(0).map((_, i) =>
                    byteCharacters.charCodeAt(i)
                );
                const byteArray = new Uint8Array(byteNumbers);

                // Blob 생성
                const blob = new Blob([byteArray], { type: "application/zip" });

                    const downloadUrl = window.URL.createObjectURL(blob);
                    const link = document.createElement("a");
                    link.href = downloadUrl;

                    let fileName = "processed.zip";
                    const dotIndex = name.lastIndexOf('.'); // 확장자 위치 찾기
                    if (dotIndex === -1) {
                        // 확장자가 없는 경우
                        fileName = name;
                    }

                    const nameWithoutExtension = name.slice(0, dotIndex); // 확장자 제외한 파일명
                    const extension = name.slice(dotIndex); // 확장자만 추출
                    fileName = `${nameWithoutExtension}_RSP${extension}`;

                    link.download =fileName;  // 다운로드할 파일 이름
                    document.body.appendChild(link);
                    link.click();

                    // 3. 다운로드 후 링크 제거
                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(downloadUrl);
                    setIsUpload(false);
                }


            // 업로드 실패
            if (status === 'error') {
                message.error(`${info.file.name} 업로드 중 문제가 발생했습니다.`);
                setIsUpload(false);
            }
        },
    };




    return (

        <div>
            {isUpload &&
                <div className="container">
                    <Spin indicator={<LoadingOutlined style={{ fontSize: 96 }} spin />} />
                </div>
            }
            <div style={{marginTop: "30px"}}>
                <Upload {...props}>
                    <Button icon={<UploadOutlined />}>배치작업 파일업로드</Button>
                </Upload>
            </div>

        </div>
    );
};

export default BatchAlgorithm;
